//Styles digital

//Tables
.rt-resizable-header-content {
  text-align: center;
  font-weight: 600;
}
.MuiDialog-container{
  height: auto !important;
  margin-bottom: 20px;
}
//input
.MuiFormLabel-root {
  font-size: 15px !important;
}

.MuiSelect-select {
  margin-top: 10px !important;
}

.form-control {
  margin-top: 5px !important;
  font-size: 15px !important;
}

//Switch
.MuiSwitch-colorPrimary.Mui-checked {
  color: #27e396 !important;
}

.MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
  background-color: #c2c2c2 !important;
}

.MuiFormControlLabel-root {
  margin-top: 5px;
}

//page login
.login-back {
  width: 100%;
  height: 100vh;
}

.style_img_login img {
  width: 100px;
  height: auto;
}

.style_text h2 {
  font-size: 17px;
  font-weight: bold;
}

.style_text h4 {
  font-size: 17px;
}

//table.

table {
  text-align: center;
  border-collapse: collapse;
  width: 100%;
}

.Conteiner-table thead {
  background-color: #3a4ee9;
  color: white;
}
.Conteiner-table {
  width: 100%;
  height: auto;
  overflow-x: auto;
  overflow-y: hidden;
}

.table-post tr, td, th .table-ecoom tr, td, th .table-user tr, td, th {
  border: 1px solid #eeedec;
  font-size: 13px;
  font-weight: 100;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}
//teble edit variety
.table-messenger tr, .table-messenger td, .table-messenger th {
  width: 204px;
}

.table-messenger tbody {
  width: 850px;
  height: 230px;
  display: block;
  overflow: auto;
}

.table-messenger {
  table-layout: fixed;
  width: 50%;
  border-collapse: collapse;
}

.table-messenger tr, .table-messenger td, .table-messenger th {
  width: 166px;
}
//teble  date
.table-date tr, .table-date td {
  width: 204px;
}

.table-date th {
  width: 210px;
  background-color: #3a4ee9;
  color: white;
  font-size: 15px;
  text-align: left;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;

}

.table-date tbody {
  width: 400px;
  height: auto;
  display: block;
  overflow: auto;
}

.table-date {
  table-layout: fixed;
  width: 50%;
  border-collapse: collapse;
}

.table-date p {
  text-align: left;
}

.table-date tr, .table-date td, .table-date th {
  width: 204px;
}

.cursor-pointer {
  cursor: pointer;
}

//teble edit variety
.table-pyments-page-price tr, .table-pyments-page-price td, .table-pyments-page-price th {
  width: 120px;
}

.table-pyments-page-price tbody {
  width: 900px;
  height: auto;
  display: block;
  overflow: auto;
}

.table-pyments-page-price {
  table-layout: fixed;
  width: 55%;
  border-collapse: collapse;
}

//teble edit variety
.table-pyments-page-price2 tr, .table-pyments-page-price2 td, .table-pyments-page-price2 th {
  width: 150px;
}

.table-pyments-page-price2 tbody {
  width: 500px;
  height: 230px;
  display: block;
  overflow: auto;
}

.table-pyments-page-price2 {
  table-layout: fixed;
  width: 40%;
  border-collapse: collapse;
}

.table-pyments-page-price2 tr, .table-pyments-page-price2 td, .table-pyments-page-price2 th {
  width: 280px;
}

//teble edit variety
.table-pyments-page-price3 tr, .table-pyments-page-price3 td, .table-pyments-page-price3 th {
  width: 150px;
}

.table-pyments-page-price3 tbody {
  width: 420px;
  height: auto;
  display: block;
  overflow: auto;
  text-align: left;
}

.table-pyments-page-price3 {
  table-layout: fixed;
  width: 20%;
  border-collapse: collapse;
}

.table-pyments-page-price3 tr, .table-pyments-page-price3 td, .table-pyments-page-price3 th {
  width: 280px;
}

//teble edit variety
.table-pyments-page-price-4 tr, .table-pyments-page-price-4 td, .table-pyments-page-price-4 th {
  width: 139px;
}

.table-pyments-page-price-4 tbody {
  width: 1389px;
  height: auto;
  display: block;
  overflow: auto;
}

.table-pyments-page-price-4 {
  table-layout: fixed;
  width: 50%;
  border-collapse: collapse;
}

.text-center {
  text-align: center;
}

.style-sw {
  border: 2px solid gainsboro;
  border-radius: 20px;
  text-align: left;

}

.style_text h2 {
  font-size: 17px;
  font-weight: bold;
  text-align: center;
}

.style_text h3 {
  font-size: 16px;
  color: #000000;
  text-align: center;
  margin-bottom: 15px;
  cursor: pointer;
}

.style_text h4 {
  font-size: 17px;
}
///////////////

.table-pyments-page-price4  tr, .table-pyments-page-price4  td, .table-pyments-page-price4  th {
  width: 113px;
}

.table-pyments-page-price4 tbody {
  width: 917px;
  height: auto;
  display: block;
  overflow: auto;
}

.table-pyments-page-price4{
  table-layout: fixed;
  width: 44%;
  border-collapse: collapse;
}

///////////////
.table-pyments-page-price5  tr, .table-pyments-page-price5  td, .table-pyments-page-price5  th {
  width: 126px;
}

.table-pyments-page-price5 tbody {
  width: 917px;
  height: auto;
  display: block;
  overflow: auto;
}
.table-pyments-page-price5{
  table-layout: fixed;
  width: 50%;
  border-collapse: collapse;
}
///////////////
.style_text p {
  margin-top: 15px;
  font-size: 16px;
  color: #000000;
  text-align: center;
  cursor: pointer;
}
