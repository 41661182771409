.modal-digital-signature-container{
	position: fixed;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0,.7); 
	z-index: 2000;
	overflow: auto;
	visibility: hidden;
	opacity: 0;
	transition: all ease 1.5s;
}

.modal-digital-signature-container-active{
	opacity: 1;
	visibility: visible;
}

.modal-digital-signature-container .empty{
	padding: 20px;
}

.modal-digital-signature-container .empty h1{
	color: lightgray;
	width: 100%;
	text-align: center;
	font-size: 16px;
}

.modal-digital-signature-container .modal-digital-signature{
	background-color: #fff;
	width: 400px;
	height: auto;
	margin:5% auto;
	border: 1px solid #0E5299;
	overflow: hidden;
	border-top-right-radius: 12px;
	border-top-left-radius: 12px;
	border-bottom-left-radius: 6px;
	border-bottom-left-radius: 6px;
}

.modal-digital-signature-heading{
	background-color: #0E5299;
}

.modal-digital-signature-heading h1{
	margin: 0;
	font-size: 17px;
	width: 100%;
	text-align: center;
	color: white;
	padding: 4px;
	padding: 4px;
	padding: 4px;
	overflow: hidden;
}


.modal-digital-signature-body{
	padding: 10px;
}

.modal-digital-signature-body .content h1{
	display: inline-block;
	width: auto;
	padding: 4px;
	margin:0;
	background-color: #E6344A;
	color: #fff;
	font-size: 11px;
	border-top-right-radius: 6px;
	border-top-left-radius: 6px;
}

.modal-digital-signature-body .content div{
	border: 1px solid lightgray;
	margin-bottom: 10px;
	padding: 6px;
	border-radius: 4px;
	border-top-left-radius: 0;
}

.modal-digital-signature-body .content p{
	margin:0;
}

.modal-digital-signature-body .content span.title{
	color: #4C8BCD;
}

.modal-digital-signature-body .content .divider{
	border-bottom: 1px solid #EBEAEA;
	width: '100%';
	height: 1px;
	margin-top: 7px;
	margin-bottom: 7px;
}

.modal-digital-signature-footer{
	background-color: #EBEAEA;
	padding: 7px;
	overflow: hidden;
}

.modal-digital-signature-footer .acept{
	background-color: #0E5299;
	border-color: #0E5299;
	color: #fff;
	border-radius:4px;
	float: right;
	cursor:pointer;
	padding: 4px;
	margin-left: 7px;
	transition: all ease 0.5s;
}

.modal-digital-signature-footer .cancel{
	background-color: lightgray;
	border-color: lightgray;
	color: #000;
	border-radius:4px;
	float: right;
	cursor:pointer;
	padding: 4px;
	margin-left: 7px;
	transition: all ease 0.5s;
}

.modal-digital-signature-footer .acept:hover{
	background-color: #4C8BCD;
	border-color: #4C8BCD;
}

.modal-digital-signature-footer .cancel:hover{
	background-color: gray;
	border-color: gray;
}